@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.scss";

html,
body {
  height: 100%;
  margin: 0;
}
.field label {
  font-size: 1rem;
  color: var(--text-color, #333);
  display: block;
  margin-bottom: 0.5rem;
}

.field input {
  font-size: 1rem;
  color: var(--text-color, #333);
  background-color: var(--surface-overlay, #fff);
  padding: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--surface-border, #ccc);
  border-radius: 0.5rem;
  appearance: none;
  outline: none;
  width: 100%;
  transition: border-color 0.3s ease;
}

.background {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 800" preserveAspectRatio="none" class="fixed left-0 top-0 min-h-screen min-w-screen"><rect width="1600" height="800" fill="%2300695c"></rect><path d="M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z" fill="%23004d40"></path><path d="M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z" fill="%2300796b"></path><path d="M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z" fill="%23009688"></path><path d="M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z" fill="%234db6ac"></path></svg>')
    no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-icon-field-left > .p-inputtext {
  padding-left: 2.5rem !important;
}

/* .p-button:focus,
.p-button:active {
  box-shadow: none;
  outline: none;
} */
.p-inputtext:focus {
  box-shadow: none;
}

.menu-item-danger .pi {
  color: red !important;
}
.menu-item-danger span {
  color: red !important;
}

.danger {
  background-color: #e74c3c;
  color: white;
}
.info {
  color: #3498db;
  color: white;
}
.warning {
  background-color: #f1c40f;
  color: black;
}
.success {
  background-color: #2ecc71;
  color: white;
}
.secondary {
  background-color: #95a5a6;
  color: white;
}
